<template>
	<div>
		<h1>Move file</h1>
		<div style="margin-left: -20px">
			<BlFileMoveDialogTree :items="items" @open="open($event)" @select="select($event)" />
		</div>
	</div>
</template>

<script>
import BlFileMoveDialogTree from './FileMoveDialogTree'
import { Api } from 'ModelBundle'
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlFileMoveDialog',
	components: {
		BlFileMoveDialogTree
	},
	data() {
		return {
			items: [{
				id: -1,
				name: this.$t('file.explorer.rootDir'),
				path: null
			}]
		}
	},
	methods: {
		open(item) {
			item.opened = !item.opened
			if(!item.children && !item.loading) {
				item.loading = true
				const fullPath = this.getFullPath(item)
				let request = {
					fields: [
						{name: 'id'},
						{name: 'name'},
						{name: 'path'}
					],
					filters: ['&',
						fullPath !== null ? ['path', '=', fullPath] : ['path', 'NULL'],
						['type', '=', 1]
					],
					limit: -1,
					model: 'internals.filesystem',
					metadata: false
				}
				Api.post('api/', {data: request}).then(resp => {
					item.children = resp.data.data
					item.loading = false
				})
			}
		},
		getFullPath(item) {
			if(item.id == -1) return null
			return (item.path ? (item.path + '/') : '') + item.name
		},
		select(item) {
			Dialog.close(true, true, this.getFullPath(item))
		}
	},
	mounted() {
		this.open(this.items[0])
	}
}
</script>

<style scoped lang="scss">
</style>