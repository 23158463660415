import { Api } from 'ModelBundle'
import { Auth } from 'AuthBundle'
import { ViewServices } from './ViewServices.js'
import { EventEmitter } from './EventEmitter.js'
import { DateFormat } from './DateFormat.js'

/**
 * Handles main interface variables (favicon, image, colors, ...)
 */
export const Variables = {
	_initialized: false,
	icon: null,
	name: null,
	favicon: null,
	upgrading: false,
	upgradingEvent: new EventEmitter(),
	globalLoading: false,
	globalLoadingEvent: new EventEmitter(),
	colors: {
		primary: null,
		secondary: null,
		light: {
			background: '#F5F5F5',
			surface: '#FFFFFF',
			border: '#DEDEDE',
			onSurface: '#090909',
			legend: '#4A4A4A',
			onPrimary: '#FFFFFF',
			onSecondary: '#FFFFFF',
			selectedBg: '#E8EFF6',
			selectedFg: '#156983'
		},
		dark: {
			background: '#202124',
			surface: '#2A2B2E',
			border: '#404040',
			onSurface: '#FFFFFF',
			legend: '#CCCCCC',
			onPrimary: '#F6F6F6',
			onSecondary: '#000000',
			selectedBg: '#23364E',
			selectedFg: '#65B1C9'
		},
		global: {
			borderRadius: '10px',
			error: '#B00020',
			warning: '#786136',
			warningBg: '#FFF3B6'
		}
	},
	loaded: new EventEmitter(),
	themeSetting: 'auto',
	theme: null,
	mobile: null,
	mainMenu: null,
	accessibilityMode: false,
	i18n: {},
	notificationCount: null,
	currentlyOpenedMenu: null,

	/**
	 * Initialize variables elements
	 */
	initialize(force = false) {
		//Auto theme listeners
		if(this.themeSetting == 'auto') this.theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
		else this.theme = this.themeSetting
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', ev => {
			if(this.themeSetting == 'auto') {
				if(ev.matches) this.theme = 'dark'
				else this.theme = 'light'
			}
			this.applyColors()
		})

		if(localStorage.getItem('Interface.primary_color')) {
			this.setCssVariable('primary', localStorage.getItem('Interface.primary_color'))
			this.setCssVariable('surface', this.colors[this.theme].surface)
		}

		if(!Auth.isAuthenticated() || force) Api.get('interface/variables/').then(resp => this.setInterfaceVariables(resp), () => this.applyColors())

		if(!this._initialized) {
			Auth.events.logout.subscribe(() => this.applyColors())
			this._initialized = true
		}

		this.setMobile()
		window.addEventListener('resize', () => this.setMobile())
	},

	/**
	 * Set mobile variable
	 */
	setMobile() {
		this.mobile = window.innerWidth <= 900 && matchMedia('(pointer:coarse)').matches
	},

	/**
	 * Set variable elements
	 * @param {Object} data
	 */
	setInterfaceVariables(data, direction = null, userPreferences = null) {
		if(data) {
			if(data.i18n) this.i18n = data.i18n
			if(data.icon) this.icon = data.icon
			if(data.name) this.name = data.name
			if(data.favicon) {
				this.favicon = data.favicon
				this.setFavicon()
			}
			if(data.name) document.querySelector('head > title').innerText = data.name
			if(data.colors) {
				if(data.colors.primary) {
					this.colors.primary = data.colors.primary
					localStorage.setItem('Interface.primary_color', this.colors.primary)
				}
				if(data.colors.secondary) this.colors.secondary = data.colors.secondary
				if(data.colors.onPrimary) {
					this.colors.light.onPrimary = data.colors.onPrimary
					this.colors.dark.onPrimary = data.colors.onPrimary
				}
				if(data.colors.onSecondary) {
					this.colors.light.onSecondary = data.colors.onSecondary
					this.colors.dark.onSecondary = data.colors.onSecondary
				}
				if(data.colors['dark.onPrimary']) this.colors.dark.onPrimary = data.colors['dark.onPrimary']
				if(data.colors['light.onPrimary']) this.colors.light.onPrimary = data.colors['light.onPrimary']
				if(data.colors['dark.onSecondary']) this.colors.dark.onSecondary = data.colors['dark.onSecondary']
				if(data.colors['light.onSecondary']) this.colors.light.onSecondary = data.colors['light.onSecondary']
			}
		}
		if(direction) document.body.setAttribute('dir', direction)
		if(userPreferences) {
			if(userPreferences.theme && userPreferences.theme != 'auto') {
				this.themeSetting = userPreferences.theme
				this.theme = this.themeSetting
			}
			if(userPreferences.accessibilityMode) this.accessibilityMode = true

			DateFormat._initialize(userPreferences.language, userPreferences.country.toLowerCase()).once(() => this.loaded.emit())
		}
		else this.loaded.emit()
		this.applyColors()
	},

	/**
	 * Set CSS variables for colors
	 */
	applyColors() {
		this.setCssVariable('primary', this.colors.primary)
		this.setCssVariable('secondary', this.colors.secondary)
		for(let key in this.colors[this.theme]) this.setCssVariable(key, this.colors[this.theme][key])
		for(let key in this.colors.global) this.setCssVariable(key, this.colors.global[key])
		document.documentElement.style.setProperty('color-scheme', this.theme)
		if(this.accessibilityMode) document.body.classList.add('accessibility_mode')
		else document.body.classList.remove('accessibility_mode')
		document.querySelector('meta[name="theme-color"]').setAttribute('content', Auth.isAuthenticated() ? this.colors[this.theme].background : this.colors.primary)
	},

	/**
	 * Set favicon
	 */
	setFavicon() {
		let link = document.createElement('link')
		link.rel = 'icon'
		link.href = this.favicon
		document.getElementsByTagName('head')[0].appendChild(link)
	},

	/**
	 * Get icon
	 * @return {string}
	 */
	getIcon() {
		return this.icon
	},

	/**
	 * Set CSS variable
	 * @param {string} name
	 * @param {string} value
	 */
	setCssVariable(name, value) {
		name = name.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
		document.documentElement.style.setProperty('--bl-' + name, value)
	},

	/**
	 * Set theme
	 * @param {string} theme
	 */
	setTheme(theme) {
		if(!['auto', 'dark', 'light'].includes(theme)) throw Error('Invalid theme')
		this.themeSetting = theme
		if(this.themeSetting == 'auto') this.theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
		else this.theme = theme
		this.applyColors()
	},

	/**
	 * Set / unset interface in global loading mode
	 * @param {Boolean} value
	 */
	setGlobalLoading(value = true) {
		this.globalLoading = value
		this.globalLoadingEvent.emit()
	},

	/**
	 * Set / unset interface upgrading
	 * @param {Boolean} value
	 */
	setUpgrading(value = true) {
		if(!Auth.isAuthenticated() && this.upgrading && !value) this.initialize()
		this.upgrading = value
		this.upgradingEvent.emit()
	}
}

ViewServices.variables = Variables
