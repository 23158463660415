<template>
	<div @dragover.prevent="dragOver = true" @dragleave="dragOver = false" @drop.prevent.stop="dropHandler($event)" class="fileExplorerContainer" :class="{embeded: embeded}">
		<div class="dragOver" v-if="dragOver">
			<icon>file_download</icon>
		</div>
		<div class="bl-card" style="display: flex; flex-direction: column; margin-bottom: -5px;" :style="{width: previewedFileWidth + 'px', flex: previewedFile ? 'none' : '1'}">
			<div class="navigation bl-light-scroll">
				<div v-for="(item, index) in navigation" :key="item.path">
					<span @click="openDirectory(item.path)">{{ item.name }}</span>
					<icon v-if="index != navigation.length - 1">navigate_next</icon>
				</div>
				<div style="flex: 1;"></div>
				<div class="bl-toggle viewTypeToggle">
					<div :class="{active: view == 'grid'}" @click="view = 'grid'" v-bl-tooltip="$t('file.explorer.view.grid')"><icon>grid_view</icon></div>
					<div :class="{active: view == 'list'}" @click="view = 'list'" v-bl-tooltip="$t('file.explorer.view.list')"><icon>list</icon></div>
				</div>
				<BlButton :label="$t('file.explorer.upload')" icon="download" classlist="dense outlined" @click="uploadFiles()" />
				<BlButton :label="$t('file.explorer.newDirectory')" icon="create_new_folder" classlist="dense outlined" @click="createDirectory()" />
			</div>
			<div class="elements bl-light-scroll" v-if="view == 'grid'">
				<div v-for="item in items" :key="item.id">
					<div class="item" @click="open(item)">
						<div class="previewContainer" :style="{'background-image': getItemPreview(item)}">
							<icon v-if="item.type == 1">folder</icon>
						</div>
						<div class="name">
							<BlFileExplorerElementIcon :item="item" />
							<b>{{ item.srcName ? item.srcName : item.name }}</b>
							<icon class="publicPreview" v-if="item.publicId">public</icon>
							<button class="bl-icon-button" v-if="item.type == 1" @click="$event.stopPropagation(); openSidepanel(item, true);">edit</button>
						</div>
					</div>
				</div>
			</div>
			<div class="elementsList bl-light-scroll" v-if="view == 'list'">
				<table class="bl-datatable dense">
					<thead>
						<tr>
							<th @click="revertSort = !revertSort; openDirectory(null, false)">
								<div style="display: flex;">
									<span>{{ $t('internals.filesystems.property.name') }}</span>
									<icon class="sort" :class="{reversed: revertSort}">arrow_upward</icon>
								</div>
							</th>
							<th style="cursor: default; width: 200px;">{{ $t('internals.filesystems.property.creationDate') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in items" :key="item.id" @click="open(item)">
							<td>
								<div class="name">
									<BlFileExplorerElementIcon :item="item" />
									<b>{{ item.name }}</b>
								</div
>							</td>
							<td><div v-if="item.type != 1">{{ item.creationDate }}</div></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div v-if="previewedFile" v-bl-resize="{value: previewedFileWidth, min: 214}" @resize="updatePreviewSize($event)" @currentresize="previewFileResizing = $event" style="height: calc(100% + 5px);"></div>
		<div class="bl-card previewFile bl-light-scroll" v-if="previewedFile && previewedFile.type != 1" style="margin-bottom: -5px">
			<div class="topBar">
				<img :src="'https://static.mixsuite.fr/file_icons/' + previewedFile.icon + '.svg'" />
				<h4>
					<div>{{ previewedFile.srcName ? previewedFile.srcName : previewedFile.name }}</div>
					<h5 v-if="previewedFile.srcName">{{ previewedFile.name }}</h5>
				</h4>
				<BlButton :label="$t('file.explorer.action.download')" icon="download" @click="downloadFile()" />
				<button class="bl-icon-button" v-bl-tooltip="'Close'" @click="previewedFile = null">close</button>
			</div>
			<BlTabs style="margin: -15px -5px 0 -5px; flex: 1;">
				<BlTab>
					<BlTabHeader>
						<icon>article</icon>
						{{ $t('file.explorer.previewTab') }}
					</BlTabHeader>
					<BlTabBody>
						<div style="margin-top: -15px; margin-bottom: -2px;" class="bl-loader-line" :style="{opacity: iframeLoaded ? 0 : 1}"></div>
						<iframe :style="{pointerEvents: previewFileResizing ? 'none' : null}" :class="{microsoft: fileDetails.embededViewer.vendor == 'microsoft', google: fileDetails.embededViewer.vendor == 'google'}" v-if="iframeSrc && showIframe" ref="iframe" :src="iframeSrc" />
					</BlTabBody>
				</BlTab>
				<BlTab>
					<BlTabHeader>
						<icon>info</icon>
						{{ $t('file.explorer.detailsTab') }}
					</BlTabHeader>
					<BlTabBody>
						<div class="fileDetails" v-if="fileDetails">
							<h4>{{ $t('file.explorer.detailsAccessTab') }}</h4>
							<ul class="accessDetails">
								<li>
									<BlProfilePicture />
								</li>
							</ul>
							<h4>{{ $t('file.explorer.detailsSystemTab') }}</h4>
							<table>
								<tr>
									<td>{{ $t('file.explorer.details.extension') }} :</td>
									<td>{{ fileDetails.extension }}</td>
								</tr>
								<tr>
									<td>{{ $t('file.explorer.details.type') }} :</td>
									<td>{{ fileDetails.fileType }}</td>
								</tr>
								<tr>
									<td>{{ $t('file.explorer.details.size') }} :</td>
									<td>{{ fileDetails.size }}</td>
								</tr>
								<tr>
									<td>{{ $t('file.explorer.details.created') }} :</td>
									<td>{{ fileDetails.created }}</td>
								</tr>
								<tr v-if="fileDetails.publicLink">
									<td>{{ $t('file.explorer.details.publicLink') }} :</td>
									<td>
										<div v-bl-input class="publicLinkInput">
											<input type="text" v-model="fileDetails.publicLink" disabled @focus="event.target.select()" />
											<icon class="suffix" @click="copyPublicLink()">content_copy</icon>
										</div>
									</td>
								</tr>
							</table>
							<h4>{{ $t('file.explorer.detailsActionTab') }}</h4>
							<div style="display: flex; justify-content: space-around;">
								<button class="bl-large-button" @click="rename()">
									<icon>edit</icon>
									{{ $t('file.explorer.action.rename') }}
								</button>
								<button class="bl-large-button" @click="move()">
									<icon>drive_file_move_rtl</icon>
									{{ $t('file.explorer.action.move') }}
								</button>
								<button class="bl-large-button" @click="deleteItem()">
									<icon>delete</icon>
									{{ $t('file.explorer.action.delete') }}
								</button>
								<button class="bl-large-button" v-if="fileDetails.publicLink" @click="privateFile()">
									<icon>public_off</icon>
									{{ $t('file.explorer.action.makePrivate') }}
								</button>
								<button class="bl-large-button" v-else @click="publicFile()">
									<icon>public</icon>
									{{ $t('file.explorer.action.makePublic') }}
								</button>
							</div>
						</div>
					</BlTabBody>
				</BlTab>
				<BlTab disabled>
					<BlTabHeader>
						<icon>view_timeline</icon>
						{{ $t('file.explorer.activityTab') }}
					</BlTabHeader>
					<BlTabBody>
						Activity
					</BlTabBody>
				</BlTab>
			</BlTabs>
		</div>
		<div class="bl-card previewFile bl-light-scroll" v-if="previewedFile && previewedFile.type == 1" style="margin-bottom: -5px">
			<div class="topBar">
				<icon class="folderSmallIcon">folder_open</icon>
				<h4>
					<div>{{ previewedFile.srcName ? previewedFile.srcName : previewedFile.name }}</div>
					<h5 v-if="previewedFile.srcName">{{ previewedFile.name }}</h5>
				</h4>
				<button class="bl-icon-button" v-bl-tooltip="'Close'" @click="previewedFile = null">close</button>
			</div>
			<div class="fileDetails" v-if="fileDetails">
				<h4>{{ $t('file.explorer.detailsAccessTab') }}</h4>
				<ul class="accessDetails">
					<li>
						<BlProfilePicture />
					</li>
				</ul>
				<h4>{{ $t('file.explorer.detailsSystemTab') }}</h4>
				<table>
					<tr>
						<td>{{ $t('file.explorer.details.created') }} :</td>
						<td>{{ fileDetails.created }}</td>
					</tr>
					<tr v-if="fileDetails.publicLink">
						<td>{{ $t('file.explorer.details.publicLink') }} :</td>
						<td>
							<div v-bl-input class="publicLinkInput">
								<input type="text" v-model="fileDetails.publicLink" disabled @focus="event.target.select()" />
								<icon class="suffix" @click="copyPublicLink()">content_copy</icon>
							</div>
						</td>
					</tr>
				</table>
				<h4>{{ $t('file.explorer.detailsActionTab') }}</h4>
				<div style="display: flex; justify-content: space-around;">
					<button class="bl-large-button" @click="rename()">
						<icon>edit</icon>
						{{ $t('file.explorer.action.rename') }}
					</button>
					<button class="bl-large-button" disabled>
						<icon>drive_file_move_rtl</icon>
						{{ $t('file.explorer.action.move') }}
					</button>
					<button class="bl-large-button" @click="deleteItem()">
						<icon>delete</icon>
						{{ $t('file.explorer.action.delete') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { UploadHelpers } from 'FileBundle'
import { Api } from 'ModelBundle'
import { Auth } from 'AuthBundle'
import { Dialog, Snackbar, Router, ViewServices, Variables } from 'InterfaceBundle'
import BlFileExplorerElementIcon from './FileExplorerElementIcon'

export default {
	name: 'BlFileExplorer',
	props: ['root'],
	data() {
		return {
			items: [],
			navigation: [],
			previewedFile: null,
			dragOver: false,
			previewedFileWidth: null,
			iframeSrc: null,
			iframeLoaded: false,
			reloadTimeoutInstance: null,
			previewFileResizing: false,
			fileDetails: null,
			uploadTimeout: null,
			embeded: false,
			view: 'grid',
			revertSort: false,
			showIframe: true
		}
	},
	components: {
		BlFileExplorerElementIcon
	},
	created() {
		if(this.root) this.embeded = true
		if(localStorage.getItem('Fileexplorer.previewedFileWidth')) this.previewedFileWidth = parseFloat(localStorage.getItem('Fileexplorer.previewedFileWidth'))
		if(!this.embeded) Router.customHandler = route => this.routerHandler(route)
		let initialDir = this.root ? this.root : (ViewServices.routeParams.dir ? decodeURIComponent(ViewServices.routeParams.dir) : null)
		this.openDirectory(initialDir)
	},
	unmounted() {
		if(!this.embeded) Router.customHandler = null
	},
	methods: {
		getItemPreview(item) {
			if(item.type != 0) return null
			if(item.preview) return 'url(\'data:image/jpeg;base64,' + item.preview + '\')'
			return 'url(\'https://static.mixsuite.fr/blocks/file_preview_fallback.png\')'
		},
		open(item) {
			if(item.uploading) return
			else if(item.type == 1) this.openDirectory(item.path ? item.path + '/' + item.name : item.name)
			else this.openSidepanel(item)
		},
		openSidepanel(item) {
			const url = item.uuid ? 'file-details/' + item.uuid : 'directory/details/' + item.id
			Api.get(url).then(resp => {
				this.fileDetails = resp
				this.iframeSrc = null
				if(item.type != 1) {
					this.$nextTick(() => {
						let fullPath = window.location.origin + '/api/file/' + item.uuid + '?bearer=' + Auth.getToken()
						//If not mobile, force native iframe view for pdf
						if(this.fileDetails.embededViewer.vendor == 'google' && !Variables.mobile) {
							this.iframeSrc = fullPath + '&disposition=inline'
							this.fileDetails.embededViewer.vendor = 'native'
						}
						else this.iframeSrc = this.fileDetails.embededViewer.url.replace('{{url}}', encodeURIComponent(fullPath)).replace('{{token}}', Auth.getToken())
						this.iframeLoaded = false
						this.$nextTick(() => {
							this.$refs.iframe.onload = () => this.iframeLoaded = true
							if(this.fileDetails.embededViewer.vendor == 'google') this.reloadTimeout()
						})
					})
				}
			})
			if(!this.previewedFileWidth) this.previewedFileWidth = this.$el.offsetWidth * 0.6
			this.previewedFile = item
		},
		downloadFile() {
			Api.openWindow('file/' + this.previewedFile.uuid)
		},
		openDirectory(directory = null, pushState = true) {
			if(this.initialDirectoryOpened && pushState && !this.embeded) {
				Router.customHandlerPush('file/explorer', {dir: directory ? encodeURIComponent(directory) : null})
			}
			this.initialDirectoryOpened = true
			let request = {
				fields: [
					{name: 'id'},
					{name: 'publicId'},
					{name: 'uuid'},
					{name: 'name'},
					{name: 'srcName'},
					{name: 'path'},
					{name: 'type', formatted: false},
					{name: 'preview'},
					{name: 'icon'},
					{name: 'creationDate'}
				],
				filters: directory ? ['path', '=', directory] : ['path', 'NULL'],
				limit: 100,
				model: 'internals.filesystem',
				sort: [{
					field: 'type',
					order: 'DESC'
				}, {
					field: 'name',
					order: this.revertSort ? 'DESC' : 'ASC'
				}]
			}
			Api.post('api/', {data: request}).then(resp => {
				this.items = resp.data.data

				//Set navigation
				this.navigation = [{
					name: this.$t('file.explorer.rootDir'),
					path: null
				}]
				let parts = directory ? directory.split('/') : []
				for(let partIndex in parts) {
					let path = parts.slice(0, partIndex)
					path.push(parts[partIndex])
					this.navigation.push({
						name: parts[partIndex],
						path: path.join('/')
					})
				}
				if(this.root) this.navigation = this.navigation.slice(this.root.split('/').length)
			})
		},
		reloadTimeout() {
			this.reloadTimeoutInstance = setTimeout(() => {
				if(this.$refs.iframe && this.$refs.iframe.contentWindow[0]) {
					this.iframeLoaded = true
					clearTimeout(this.reloadTimeoutInstance)
					this.reloadTimeoutInstance = null
				}
				else {
					this.showIframe = false
					setTimeout(() => {
						this.showIframe = true
						this.reloadTimeout()
					}, 10)
				}
			}, 2000)
		},
		rename() {
			const i18nPrefix = 'file.explorer.' + (this.previewedFile.type == 1 ? 'dialogRenameDir.' : 'dialogRenameFile.')
			Dialog.prompt({
				accept: this.$t(i18nPrefix + 'accept'),
				cancel: this.$t(i18nPrefix + 'cancel'),
				required: true,
				promptLabel: this.$t(i18nPrefix + 'promptLabel'),
				promptValue: this.previewedFile.name.split('.')[0]
			}).then(fileName => {
				if(this.previewedFile.type != 1) fileName += '.' + this.previewedFile.name.split('.')[1]
				Api.put(this.previewedFile.type == 1 ? 'directory/rename/' + this.previewedFile.id : '/file-rename/' + this.previewedFile.uuid, {name: fileName}).then(details => {
					this.fileDetails = details
					this.previewedFile.name = details.name
				})
			})
		},
		getCurrentPath() {
			return this.navigation[this.navigation.length - 1].path
		},
		deleteItem() {
			const i18nPrefix = 'file.explorer.' + (this.previewedFile.type == 1 ? 'dialogDeleteDir.' : 'dialogDeleteFile.')
			Dialog.confirm({
				title: this.$t(i18nPrefix + 'title{file}', {file: this.previewedFile.name}),
				accept: this.$t(i18nPrefix + 'accept'),
				cancel: this.$t(i18nPrefix + 'cancel')
			}).then(() => {
				Api.delete(this.previewedFile.type == 1 ? '/directory/delete/' + this.previewedFile.id : '/file-delete/' + this.previewedFile.uuid).then(resp => {
					if(resp.success) {
						this.previewedFile = null
						Snackbar.open({text: this.$t(i18nPrefix + 'success')})
						this.openDirectory(this.getCurrentPath())
					}
					else Snackbar.open({text: this.$t(i18nPrefix + 'error'), error: true})
				})
			})
		},
		createDirectory() {
			Dialog.prompt({
				title: this.$t('file.explorer.dialogDirectory.title'),
				accept: this.$t('file.explorer.dialogDirectory.accept'),
				cancel: this.$t('file.explorer.dialogDirectory.cancel'),
				required: true,
				promptLabel: this.$t('file.explorer.dialogDirectory.promptLabel')
			}).then(dirName => {
				Api.post('/directory/create/', {name: dirName, path: this.getCurrentPath()}).then(resp => {
					if(resp.success) {
						Snackbar.open({text: this.$t('file.explorer.dialogDirectory.success')})
						this.openDirectory(this.getCurrentPath())
					}
					else Snackbar.open({text: this.$t('file.explorer.dialogDirectory.error'), error: true})
				})
			})
		},
		uploadFiles() {
			if(this.uploadTimeout) return
			this.uploadTimeout = true
			setTimeout(() => this.uploadTimeout = false, 500)
			UploadHelpers.upload(true, null, (success, fileOption) => {
				if(success) {
					let item = JSON.parse(JSON.stringify(fileOption))
					item.uploading = true
					item.type = 0
					this.items.push(item)
				}
			}, () => this.$forceUpdate(), this.finishUpload)
		},
		dropHandler(event) {
			this.dragOver = false
			UploadHelpers.dropHandler(event, true, fileOption => {
				let item = JSON.parse(JSON.stringify(fileOption))
				item.uploading = true
				item.type = 0
				this.items.push(item)
			}, () => this.$forceUpdate(), this.finishUpload)
		},
		finishUpload(fileOption) {
			if(fileOption.error) this.items = this.items.filter(i => i.uploading != true)
			else {
				Api.post('/upload/', {
					location: this.getCurrentPath(),
					name: fileOption.name,
					lastModified: fileOption.lastModified,
					tmpPath: fileOption.tmpName
				}).then(() => {
					this.openDirectory(this.getCurrentPath())
				})
			}
		},
		updatePreviewSize(size) {
			this.previewedFileWidth = size
			localStorage.setItem('Fileexplorer.previewedFileWidth', size)
		},
		routerHandler(route) {
			if(route.definition.component == 'BlFileExplorerContainer') {
				this.openDirectory(route.args.dir ? decodeURIComponent(route.args.dir) : null, false)
				return true
			}
			return false
		},
		publicFile() {
			Dialog.confirm({
				title: this.$t('file.explorer.dialogPublic.title{file}', {file: this.previewedFile.name}),
				accept: this.$t('file.explorer.dialogPublic.accept'),
				cancel: this.$t('file.explorer.dialogPublic.cancel')
			}).then(() => {
				Api.put('/file-public/' + this.previewedFile.uuid).then(resp => {
					this.fileDetails.publicLink = resp.link
					this.previewedFile.publicId = resp.link
				})
			})
		},
		privateFile() {
			Dialog.confirm({
				title: this.$t('file.explorer.dialogPrivate.title{file}', {file: this.previewedFile.name}),
				accept: this.$t('file.explorer.dialogPrivate.accept'),
				cancel: this.$t('file.explorer.dialogPrivate.cancel')
			}).then(() => {
				Api.delete('/file-public/' + this.previewedFile.uuid).then(() => {
					this.fileDetails.publicLink = null
					this.previewedFile.publicId = null
				})
			})
		},
		copyPublicLink() {
			navigator.clipboard.writeText(this.fileDetails.publicLink)
			Snackbar.open({text: 'URL in clipboard'})
		},
		move() {
			Dialog.custom({
				component: 'BlFileMoveDialog'
			}).then(resp => {
				Api.put('/file-move/' + this.previewedFile.uuid, {path: resp}).then(() => {
					this.previewedFile = null
					Snackbar.open({text: this.$t('success')})
					this.openDirectory(this.getCurrentPath())
				})
			})
		}
	}
}
</script>

<style scoped lang="scss">
.embeded .previewFile {
	overflow-x: hidden;
	overflow-y: auto;
}

.previewFile {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;

	.topBar {
		z-index: 1;
		display: flex;
		align-items: center;

		img {
			margin: 20px;
			width: 18px;
			height: 18px;
		}

		.folderSmallIcon {
			border-radius: 4px;
			margin: 20px;
		}

		h4 {
			flex: 1;
			font-size: 18px;
			font-family: Product sans;
			margin: 0;
			padding: 0;

			h5 {
				color: var(--bl-legend);
				font-weight: normal;
				font-size: 12px;
				font-family: 'Roboto';
				margin: 0;
				padding: 0;
			}
		}

		button {
			margin-right: 10px;
		}
	}

	iframe {
		background-color: #D1D1D1;
		flex: 1;
		border: none;
		width: 100%;
		height: 100%;
	}

	iframe.microsoft {
		margin: -1px;
	}

	iframe.google {
		height: calc(100% - 49px);
	}

	.hidePopoutButton {
		position: absolute;
		width: 50px;
		height: 40px;
		background-color: #D1D1D1;
		right: 12px;
		top: 114px;
		z-index: 2;
	}
}

.navigation {
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--bl-border);
	margin: 0 -5px 5px -5px;
	padding: 5px 10px 10px 10px;
	overflow-x: auto;
	min-height: 31px;
	gap: 10px;

	div {
		display: flex;
		align-items: center;

		icon {
			margin-top: -2px;
			margin-right: -10px;
		}

		span {
			cursor: pointer;
			border-radius: var(--bl-border-radius);
			padding: 7px 12px;
			transition: background-color .2s;
			white-space: nowrap;
		}

		span:hover {
			background-color: var(--bl-background);
		}
	}
}

.elementsList {
	overflow-y: scroll;
	margin: -5px;
	flex: 1;

	table.bl-datatable {
		margin: 0;
		width: 100%;
		border-radius: 0;

		td, th {
			border-right: 0;
			border-left: 0;
		}

		thead {
			tr:first-child th:first-child, tr:first-child th:last-child {
				border-radius: 0;
			}

			th {
				position: sticky;
				top: 0;
				background-color: var(--bl-surface);

				icon.sort {
					font-size: 16px;
					margin-left: 5px;
					vertical-align: text-top;
					transition: transform .2s;
				}

				icon.sort.reversed {
					transform: rotate(180deg);
				}
			}
		}

		tbody {
			tr > td {
				cursor: pointer;

				.name {
					display: flex;
					align-items: center;
					font-family: 'Product sans';

					> div {
						margin: -7px 0 -7px -7px;
					}

					> b {
						font-weight: 400;
					}
				}
			}
		}
	}
}

.elementsList:not(:hover) {
	padding-right: 5px;
}

.elements {
	display: flex;
	flex-wrap: wrap;
	overflow-y: scroll;

	.item {
		user-select: none;
		margin: 5px;
		border: 1px solid var(--bl-border);
		border-radius: var(--bl-border-radius);
		width: 200px;
		height: 140px;
		overflow: hidden;
		cursor: pointer;
		transition: border .2s;

		.previewContainer {
			text-align: center;
			height: 100px;
			background-position: start;
			background-size: cover;
			background-repeat: no-repeat;

			icon {
				color: var(--bl-border);
				font-size: 100px;
			}
		}

		.name {
			color: var(--bl-legend);
			background-color: var(--bl-surface);
			display: flex;
			align-items: flex-start;
			max-width: 100%;
			padding-right: 5px;
			transition: background-color .2s;

			b {
				font-weight: normal;
				padding-top: 8px;
				white-space: nowrap;
				overflow: hidden;
				flex: 1;
				line-height: 26px;
				text-overflow: ellipsis;
				display: block;
			}

			icon.publicPreview {
				color: var(--bl-legend);
				font-size: 20px;
				margin: 10px 5px 0 5px;
			}

			button.bl-icon-button {
				display: none;
			}
		}
	}

	.item:hover {
		border-color: color-mix(in srgb, var(--bl-border) 70%, var(--bl-on-surface));

		.name {
			background-color: var(--bl-background);

			button.bl-icon-button {
				display: block;
				font-size: 18px;
				margin-top: 2px;
				line-height: 18px;
			}
		}
	}
}

.folderSmallIcon {
	display: block;
	margin: 11px;
	width: 18px;
	height: 18px;
	font-size: 14px;
	text-align: center;
	line-height: 18px;
	background-color: #767676;
	color: white;
	border-radius: 2px;
}

.elements:not(:hover) {
	padding-right: 5px;
}

.fileDetails {
	padding: 10px;

	h4 {
		margin: 10px 0;
		font-family: Product sans;
		font-size: 16px;
	}

	table {
		width: 100%;
	}

	table tr td {
		padding: 5px;
	}

	table tr td:first-child {
		white-space: nowrap;
		width: 1px;
		color: var(--bl-legend);
		padding-right: 20px;
	}

	.accessDetails {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			align-items: center;
			padding: 2px 5px;

			span {
				margin-left: 5px;
			}
		}
	}
}

.dragOver {
	width: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	z-index: 4;
	pointer-events: none;

	icon {
		color: var(--bl-on-primary);
		animation: dragOverAnimate 1s infinite;
		font-size: 80px;
		padding: 50px;
		border-radius: 50%;
		background-color: var(--bl-primary);
		margin: 0 10px;
	}
}

@keyframes dragOverAnimate {
	0% {
		box-shadow: 0 0 0 1px var(--bl-primary);
	}

	30% {
		box-shadow: 0 0 0 5px var(--bl-primary);
	}

	70% {
		box-shadow: 0 0 0 5px var(--bl-primary);
	}

	100% {
		box-shadow: 0 0 0 1px var(--bl-primary);
	}
}

.publicLinkInput.bl-input {
	margin: -10px 0;

	.suffix {
		cursor: pointer;
		font-size: 18px;
		margin-top: 11px;
		margin-right: -2px;
	}

	input {
		font-family: monospace;
		font-size: 12px;
		background: var(--bl-background);
		padding-right: 30px;
		width: calc(100% - 30px);
	}
}

.fileExplorerContainer {
	display: flex;
}

.fileExplorerContainer.embeded {
	margin-bottom: 5px;
}
.fileExplorerContainer:not(.embeded) {
	height: calc(100% - 5px);
}

.bl-toggle.viewTypeToggle {
	flex-wrap: nowrap;

	div  > icon {
		font-size: 20px;
		margin: -2px;
		display: block;
	}
}
</style>